<template>

    <v-card
    class="mx-auto mt-6 pb-2 "
    >    
        <v-toolbar
            ligth
            cards                
            flat
            color="green lighten-4"
        >
            <v-card-title class="text-h6 font-weight-regular">
            Düsentabellen
            </v-card-title>
            <v-spacer> </v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        fab
                        v-bind="attrs"
                        v-on="on"
                        class="mt-10 mx-5"
                        @click="downloadCSVData"
                    >
                        <v-icon dark>
                            mdi-database-export-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Export to CSV</span>                           
            </v-tooltip>                
            
        </v-toolbar>
        <v-dialog v-model="ctrlSMNF.isVisible" :width="ctrlSMNF.width">
            <StatusMaskNozzleFlowCard 
                :item="ctrlSMNF.item"
                :options="ctrlSMNF.options" 
                :isVisible.sync="ctrlSMNF.isVisible">
            </StatusMaskNozzleFlowCard>
        </v-dialog>

        <v-container fluid>
            <v-row>
                <v-col cols="12">

                    <v-select
                            v-model="selectTableTypes"
                            :items="tableTypes"
                            item-text="description"
                            item-value="id"
                            label="Düsentabellen" 
                            @change="changeTableTypes"
                        >
                    </v-select>
                </v-col>
            </v-row>
  

        </v-container>

        <v-progress-linear
            :active="isLoading"
            :indeterminate="isLoading"
            absolute
            bottom
        ></v-progress-linear>

            <v-data-table
            ref="datatable"
            :headers="headers"
            :items="items"
            :items-per-page="-1"    
            :item-class="itemRowBackground"
            hide-default-footer
        >         
            <template  v-slot:header="{ props }">
                <tr v-if="!props.mobile" class="v-data-table-header">
                    <th 
                        v-for="head in props.headers" 
                        :key="head.value"  
                        role="columnheader" 
                        :class="'text-' + head.align"
                        :style="headerStyle(head)"
                        
                    >
                        <v-btn v-if="head.value != 'pressure'" text block :style="headerTextStyle(head)" @click="openCol(head)">{{head.size}}</v-btn>
                        <!-- <span :style="headerTextStyle(head)">{{head.size}}</span> -->
                    </th>
                </tr>
            </template>                            
        </v-data-table>
        

    </v-card>

</template>


<script>

import AxiosService from "../../services/axios.service";

export default {
  
    components:{
        StatusMaskNozzleFlowCard: () => import("../../components/ctrl/showNozzleFlowCard.vue"),

    },


    data () {
        return {
            isLoading: false,
            ctrlSMNF:{
                isVisible: false,
                width: 800,
                item:{
                    name: null,
                    table_type: "",
                    meta:{
                        backcolor:"#FFFFFF"
                    },
                    setpoints:[],
                    min:null,
                    max:null,
                    titel:null  
                },
                options:{
                    title: 'Düsenkarte', 
                }

            },
            headers: [],
            items:[],
            selectTableTypes:{},
            flowcolors:[]
        

            
        }
    },


    computed: {


        user(){
            return this.$store.state.auth.user
        },

        access(){
            return this.$store.state.app.access
        },   

        company() {
            return this.$store.state.app.actCompany.meta
        },


        tableTypes(){
            return this.$store.state.app.nozzles.table_types
        },

        available_colors(){
            return this.$store.state.app.nozzles.available_colors
        },        

        // device_types(){
        //     return this.$store.state.app.devices.device_types
        // }



    },
    methods:{

        headerStyle(head){
            if(head.value == "pressure")
                return 'background-color:' + head.backcolor + '; border:none; height: 50px;'
            else{
                
                return 'background-color:' + head.backcolor   + '; border:1px solid; height: 50px; '
            }

         },
         headerTextStyle(head){
            
            return  head.backcolor == "#000000" ? " color: #ffffff;" : ""
                
         },         
        itemRowBackground(item) {

            return item.row_num % 2 == 0  ? 'style-1' : 'style-2'

        },

        openCol(head){
            console.debug('openCol',head);
            this.ctrlSMNF.item.setpoints = []
            for (const item of this.items) {
                // console.debug('openCol item',item);
                // console.debug("openCol item", item.pressure, item[head.value])
                this.ctrlSMNF.item.meta = head
                this.ctrlSMNF.item.table_type = this.tableTypes[this.selectTableTypes]
                
                // this.ctrlSMNF.item.setpoints.push([item[head.value],item.pressure])
                if(!this.ctrlSMNF.item.min || this.ctrlSMNF.item.min > item.pressure)
                    this.ctrlSMNF.item.min = item.pressure

                if(!this.ctrlSMNF.item.max || this.ctrlSMNF.item.max < item.pressure)
                    this.ctrlSMNF.item.max = item.pressure

                this.ctrlSMNF.item.setpoints.push([item.pressure,item[head.value]])
            }
            console.debug("openCol item", this.tableTypes[this.selectTableTypes])
            this.ctrlSMNF.isVisible = true
        },

        changeTableTypes(val){
            console.debug('changeTableTypes', val);
            AxiosService.get("api/company/nozzles/flow/table", {id:this.company.id, table_id:val, content:"plantprotection"}).then(
                (response) => {
                    try {
                        // console.debug('changeTableTypes res', response)
                        this.headers = []
                        this.headers.push({text:"Druck", value:"pressure", align: 'center', backcolor:"#FFFFFF"})
                        this.flowcolors = response.data.flowcolors
                        for (const item of response.data.flowcolors) {
                            this.headers.push({text:item.caption, size:item.size?item.size: "", value:item.color_id_format,sortable:false,align: 'center', backcolor:item.hex})
                        }
                        this.items = response.data.flowtable

                        // console.debug('changeTableTypes head', this.headers)

                    } catch (error) {
                        const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                        console.error(err)
                    }
                    
                },
                (error) => {
                    const err = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
                    console.error(err)
                }
            ).catch((error)=>{
                console.error(error);
            }) 


        },

        downloadCSVData(){
            let csv = ""
            csv += "Name;" + this.tableTypes[this.selectTableTypes].name + "\n"
            csv += "Description;" + this.tableTypes[this.selectTableTypes].description + "\n"
            csv += "ID;" + this.tableTypes[this.selectTableTypes].id + "\n\n"              
            csv += "pressure;"
            for (const item of  this.flowcolors) {
                csv += item.name + ";"
            }
            csv += "\n"

            let table = {}
 
            for (const item of this.items) {              

                if(!table[item.row_num])
                    table[item.row_num] = []

                table[item.row_num].push(item.pressure)

                for (const key in item) {
                    if(!isNaN(key))
                        table[item.row_num].push(item[key])
                    
                }
            }
            
            for (const k in table) {
                csv += table[k].join(';');
                    csv += "\n";
            }

            csv += "\n\n";
            csv += "Vorhandene Farben\n";

            for (const col of this.available_colors) {
                csv += col.name
                csv += "\n";
            }

            csv = csv.replaceAll(".",",")
        
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'DüsenTabelle.csv';
            anchor.click();
        },

        refreshContent(){
            console.debug('refreshContent');
            const comp = this.company
            this.isLoading = true
            this.$store.dispatch("app/getApiSetStore", {path:"api/company/nozzles/table_types", param:{store:"nozzles/table_types", id:comp.id, content:"plantprotection"}})
            .then(()=>{ 

                this.selectTableTypes = 0
                this.changeTableTypes(1)
                // this.isLoading = false
                return this.$store.dispatch("app/getApiSetStore", {path:"api/company/nozzles/colors", param:{store:"nozzles/available_colors", id:-1, content:"plantprotection"}})

            }).then(()=>{ 

                this.isLoading = false
            
            }).catch((error)=>{
                console.error(error);                
                this.isLoading = false
            })
            
        },

 
    },

    mounted() {
        console.debug('mounted');
        this.refreshContent();
    },

}
</script>

<style lang="scss">
.style-1 {
    background-color: rgba(189, 188, 188, 0.377)
  }
  .style-2 {
    background-color: rgba(219, 215, 215, 0)
  }

</style>
